import * as XLSX from "xlsx";

export class ExcelUtil {
  static async readFile(files: FileList): Promise<XLSX.WorkBook | undefined> {
    if (files.length == 0) {
      return;
    }
    const data = await files[0].arrayBuffer();
    return XLSX.read(data);
  }

  static getLastRow(workSheet: XLSX.WorkSheet) {
    let sheetRange = XLSX.utils.decode_range(workSheet['!ref']);
    let lastRow = sheetRange.e.r + 1; // range is zero based index so we have to add 1
    return lastRow;
  }

  static normalizeString(str: any) {
    if (typeof str === 'string') {
      // https://stackoverflow.com/questions/21284228/removing-control-characters-in-utf-8-string
      return str.trim().replace(/[\x00-\x1F\x7F-\x9F]/g, '');
    }
    return str;
  }

  // giá trị trả về có thể không phải là string
  static getValueOfCell(workSheet: XLSX.WorkSheet, cellAddr: string, forceString: boolean = false) {
    let str = '';
    if (workSheet[cellAddr]) {
      // https://github.com/SheetJS/sheetjs#cell-object
      // For excel, get formatted text (w)
      str = workSheet[cellAddr].w;
      if (str === undefined) {
        // For CSV, formatted text doesn't exist, get raw value (v)
        str = workSheet[cellAddr].v;
      }
    }
    if (typeof str !== 'string' && forceString) {
      str = String(str).toString();
    }
    return ExcelUtil.normalizeString(str);
  }

  static getStringValueOfCell(workSheet, cellAddr): string {
    return this.getValueOfCell(workSheet, cellAddr, true);
  }

}
