import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Const } from '@const/Const';
import { Utils } from './utils';
import { getInjector } from '.';
import { AuthService, HttpService } from '@wearewarp/ng-web';

const backendUrl = environment.backendUrl;

@Injectable()
export class ApiService extends HttpService {

  constructor(
    protected client: HttpClient,
    protected auth: AuthService,
  ) {
    super(client);
  }

  get backendUrlOrigin() {
    return backendUrl;
  }

  public static get instance(): ApiService {
    let injector = getInjector();
    return injector.get(ApiService);
  }

  // Các trường imageUrl api trả về chỉ là đường dẫn tương đối
  // Dùng hàm này để tạo thành URL hoàn chỉnh của image rồi mới hiển thi được
  // Image thì gọi vào server image
  public static getImageUrl(relativePath) {
    if (!relativePath) {
      return '';
    }
    if (Utils.isUrl(relativePath)) {
      return relativePath;
    }
    return relativePath.trim().replace(/\s+/g, '%20');
  }

  public buildUrl(path: string, version: string = 'v1'): string {
    if (/^https?:\/\//.test(path)) return path;
    if (/^http?:\/\//.test(path)) return path;
    if (/^v[0-9]+\//.test(path)) {
      return `${environment.backendUrl}/${path}`; // already included version in path
    }
    return `${environment.backendUrl}/${version}/${path}`;
  }

  download(url: string) {
    let _url = this.buildUrl(url);
    return this.GET(_url, { responseType: 'arraybuffer' });
  }

  logout() {
    this.auth.logout();
  }

  public searchUsCities(keyword: string) {
    let url = `${Const.API_SEARCH_US_CITIES}?q=${encodeURIComponent(keyword)}`;
    return this.GET(url);
  }

  public getListAdminUsers() {
    let url = `${Const.APIURI_USERS}/?adminOnly=1&limit=-1`;
    return this.GET(url);
  }

  public getListDispatcherUsersFilter() {
    let url = `${Const.APIURI_USERS}/?dispatcherOnly=1&limit=-1`;
    return this.GET(url);
  }

  public getListClientSuccessRepUsersFilter() {
    let url = `${Const.APIURI_USERS}/?clientSuccessRepOnly=1&limit=-1`;
    return this.GET(url);
  }

  public getListClientSalesRepUsersFilter() {
    let url = `${Const.APIURI_USERS}/?saleOnly=1&limit=-1`;
    return this.GET(url);
  }

  public getListAccountingUsersFilter() {
    let url = `${Const.APIURI_USERS}/?accountingOnly=1&limit=-1`;
    return this.GET(url);
  }

  // Các API export đều là POST
  public postExport(url: string, params: object, options = null) {
    return this.postDownload(url, params, options)
  }

}
