import { Order, Shipment } from "@wearewarp/types/data-model";
import { WarpId } from "@wearewarp/universal-libs";

export default class WarpUtil {

  static getShipmentCode(shipment: Shipment | { code?: string, warpId?: number, isUsingCode?: boolean }) {
    return shipment.isUsingCode ? shipment?.code : WarpId.showShipment(shipment?.warpId!);
  }

  static getOrderCode(order: Order | { code?: string, warpId?: number, isUsingCode?: boolean }) {
    return order.isUsingCode ? order?.code : WarpId.showOrder(order?.warpId!);
  }

}