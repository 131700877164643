import { ApiService } from "./api.service";
import { AWSUtil } from "./aws-util";
import filesize from 'filesize';
import { UploadFileData } from "@wearewarp/types/rest-api";

export class AttachedFileUtil {

  public static isPdf(item: {type?: string, name?: string}): boolean {
    if (!item) return false;
    if (typeof item == 'string') return (<string>item).split('?')[0].toLowerCase().endsWith('.pdf');
    if (item.type && item.type.toLowerCase() == 'application/pdf') return true;
    if (item.name && item.name.toLowerCase().endsWith('.pdf')) return true;
    return false;
  }
  
  public static attachedFileUrl(model: UploadFileData, usePresigned = false): string {
    if (!model) {
      return '';
    }
    if (model.s3Key) {
      if(usePresigned){
        return AWSUtil.getSignedUrlS3Object(model.s3Key, model.s3Bucket);
      }
      return AWSUtil.getUrlS3Object(model.s3Key, model.s3Bucket);
    }
    return `${ApiService.getImageUrl(model.url)}?name=${encodeURIComponent(model.name)}`;
  }

  public static isAttachedFile(item): boolean {
    return item && item.s3Key;
  }

  // options.standard?: "iec" | "jedec";
  public static displayFileSize(size, options?) {
    if (isNaN(Number(size))) {
      return '';
    }
    return filesize(size, options);
  }

  public static fileDesc(file: File): string {
    return `${file.name} (${this.displayFileSize(file.size)})`;
  }
}