<nz-select style="width: 100%;"
  nzBackdrop="true" [nzMode]="nzMode" nzShowSearch nzAllowClear [nzDisabled]="isDisabled" [(ngModel)]="value"
  [nzPlaceHolder]="placeholder" [nzLoading]="isLoadData" [nzDropdownClassName]="dropdownClassName"
  [nzNotFoundContent]="tplNotfound"
  [nzServerSearch]="isServerSearch" (nzOnSearch)="onSearch($event)"
  (nzFocus)="onModalFocus()"
  [nzSize]="nzSize"
  (ngModelChange)="onModelChange($event)"
  style="width: 100%;"
>
  
  <nz-option *ngFor="let item of listData" [nzLabel]="getName(item)" [nzValue]="getItemValue(item)"></nz-option>
  <nz-option *ngIf="showHint && !isDisabled" nzDisabled nzLabel="Please type at least 2 letters to search"></nz-option>
</nz-select>

<ng-template #tplNotfound>
  <div *ngIf="isLoadData" class="no-data"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
  <div *ngIf="!isLoadData" class="no-data"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
</ng-template>