import { Observable } from "rxjs";
import { ApiService } from "./api.service";

import { environment } from "@env/environment";
import { Const } from "@const/Const";

export class Pricing2Service {
    api: ApiService = null
    baseUrl: string = null

    constructor(api: ApiService) {
        this.api = api
        this.baseUrl = environment.backendUrl + `/v2/pricing/quote`
    }

    quote(payload): Observable<any> {
        const { shipmentType } = payload

        const providers = shipmentType === 'LTL' ? ['WARP'] : []
        const url = this.baseUrl + (providers?.length ? `?providers=${providers.join(',')}` : '')

        return this.api.POST(url, payload, {timeout: Const.TIMEOUT_FREIGHT_QUOTE})
    }
}