import to from "await-to-js";
import { ApiService } from "./api.service";
import { Const } from "@const/Const";

export class QuickBooksService {

  api: ApiService = null

  constructor(api: ApiService) {
    this.api = api
  }

  async checkAPIReady(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = Const.APIV2(`${Const.APIURI_FINANCES}/quickbooks/check-api-ready`);
      const [err, resp] = await to(this.api.GET(url).toPromise());
      if (err) { reject(err) }
      if (resp?.data?.isReady) {
        resolve(true);
      } else {
        reject(null);
      }
    })
  }

  async connectToQuickbooks(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = Const.APIV2(`${Const.APIURI_FINANCES}/quickbooks/get-auth-uri`);
      const [err, resp] = await to(this.api.GET(url).toPromise());
      if (err) { reject(err) }
      if (resp?.data) {
        resolve(resp.data);
      } else {
        reject(null);
      }
    })
  }

  async getLatestBillByVendor(vendorId: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = Const.APIV2(`${Const.APIURI_FINANCES}/quickbooks/latest-bill-by-vendor/${vendorId}`);
      const [err, resp] = await to(this.api.GET(url).toPromise());
      if (err) { reject(err) }
      if (resp?.data) {
        resolve(resp.data);
      } else {
        resolve(null);
      }
    })
  }

  async getAllPaymentTerms(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = Const.APIV2(`${Const.APIURI_FINANCES}/quickbooks/terms`);
      const [err, resp] = await to(this.api.GET(url).toPromise());
      if (err) { reject(err) }
      if (resp?.data?.list_data) {
        resolve(resp.data.list_data);
      } else {
        reject(null);
      }
    })
  }

  async getAllAccounts(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = Const.APIV2(`${Const.APIURI_FINANCES}/quickbooks/accounts`);
      const [err, resp] = await to(this.api.GET(url).toPromise());
      if (err) { reject(err) }
      if (resp?.data?.list_data) {
        resolve(resp.data.list_data);
      } else {
        reject(null);
      }
    })
  }

  async getBillDetail(id: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = Const.APIV2(`${Const.APIURI_FINANCES}/quickbooks/bill/${id}`);
      const [err, resp] = await to(this.api.GET(url).toPromise());
      if (err) { reject(err) }
      if (resp?.data) {
        resolve(resp.data);
      } else {
        reject(null);
      }
    })
  }
}