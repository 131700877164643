import { ApiService } from "./api.service";

import { environment } from "@env/environment";
import TTLCache from "@isaacs/ttlcache";
import { HttpService } from "@wearewarp/ng-web/auth/http-service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";


export class EventService {
    api: ApiService = null
    baseUrl: string = null

    constructor(api: ApiService, baseUrl?) {
        this.api = api
        this.baseUrl = baseUrl ?? environment.eventUrl
    }

    listEvents(object, type = 'all') {
        let url = `${this.baseUrl}/find/${object}/${type}?size=1000`;
        return this.api.GET(url)
    }

    async loadEvents(object, type = 'all') {
        let url = `${this.baseUrl}/find/${object}/${type}?size=100`;
        let allEvents = {
            count: 0,
            items: []
        }
        let cursor = null
        let events = await this.api.GET(url).toPromise()
        allEvents = events
        while (events.count == 100) {
            cursor = events.items[99].id
            events = await this.api.GET(url + `&after=${cursor}`).toPromise()
            allEvents.items = allEvents.items.concat(events.items)
            allEvents.count += events.count
        }
        return allEvents
    }

    getEvent(id) {
        let url = `${this.baseUrl}/events/${id}`;
        return this.api.GET(url)
    }

    reprocessEvent(id) {
        let url = `${this.baseUrl}/events/${id}/reprocess`;
        return this.api.POST(url)
    }
}