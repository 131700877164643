import heic2any from "heic2any";
import to from 'await-to-js';

export class ImageUtil {

  public static async resizeImageIfNeeded(file: File): Promise<File> {
    let isImage = file.type.startsWith('image/');
    if (!isImage) {
      return file;
    }
    let _file: Blob, err, name = file.name;
    if (ImageUtil.isHeic(file)) {
      // Cần convert HEIC sang JPEG vì nhiều trình duyệt chạy trên Windows không view được HEIC
      [err, _file] = await to(ImageUtil.heicToJpeg(file));
      if (err) {
        console.error(`ImageUtil.heicToJpeg failed `, err);
      }
      if (_file) {
        name = name.replace(/\.heic$/i, ".jpg")
      }
    }
    if (!_file) {
      _file = file;
    }
    const blob = await ImageUtil.resizeImage(_file);
    return new File([blob], name, {type: _file.type});
  }

  private static resizeImage(file: Blob): Promise<Blob> {
    const max_width = 1920;
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = (event) => {
        let image = new Image();
        image.onload = imageEvent => {
          let width = image.width;
          let height = image.height;
          if (width > height) {
            if (width > max_width) {
              height *= max_width / width;
              width = max_width;
            }
          } else {
            if (height > max_width) {
              width *= max_width / height;
              height = max_width;
            }
          }
          let canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;
          canvas.getContext("2d").drawImage(image, 0, 0, width, height);
          let dataUrl = canvas.toDataURL('image/jpeg', 0.8);    // compress 80%
          let resizedImage = this.dataURLToBlob(dataUrl);
          return resolve(resizedImage);
        }
        image.src = <string>(<FileReader>event.target).result;
      }
      reader.readAsDataURL(file);
    });
  }

  private static dataURLToBlob(dataURL): Blob {
    let BASE64_MARKER = ';base64,';
    if (dataURL.indexOf(BASE64_MARKER) == -1) {
      let parts = dataURL.split(',');
      let contentType = parts[0].split(':')[1];
      let raw = parts[1];
      return new Blob([raw], { type: contentType });
    }
    let parts = dataURL.split(BASE64_MARKER);
    let contentType = parts[0].split(':')[1];
    let raw = window.atob(parts[1]);
    let rawLength = raw.length;
    let uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: contentType });
  }

  private static isHeic(file: File): boolean {
    return file.type === "image/heic";
  }

  private static async heicToJpeg(input: Blob): Promise<Blob> {
    const jpgBlob = await heic2any({
      blob: input,
      toType: "image/jpeg",
      quality: 0.8    // compress 80%
    }) as Blob;
    return jpgBlob;
    // return new File([jpgBlob], file.name.replace(/\.heic$/, ".jpg"), { type: "image/jpeg" });
  }

}