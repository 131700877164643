import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { SelectClientBySearching } from './select-search';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzIconModule,
    NzSelectModule,
  ],
  declarations: [
    SelectClientBySearching,
  ],
  exports: [
    SelectClientBySearching,
  ]
})
export class SelectBySearchingModule {}